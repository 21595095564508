import React from 'react'

const Cms = () => {
  React.useEffect(() => {
    window.location.replace(process.env.GATSBY_CMS_URL)
  }, [])
  return <div>moving you to cms...</div>
}

export default Cms
